import { ResponseReferFriendsReport, ResponseReport } from "../../interfaces/ResponseReport";
import { calcValuesDotz } from "../calcValuesDotz";

export const formatDataToCSVAnalytic = (data: ResponseReport[]) => {
  const values = calcValuesDotz(data);
  return values.map((rp) => {
    return {
      CPF: rp.cpf,
      NOME: rp.name,
      COD_COLIGADA: rp.cod_coligada,
      COLIGADA: rp.name_coligada,
      EMPREENDIMENTO: rp.enterprise,
      COD_DEPARTAMENTO: rp.cod_department,
      DEPARTAMENTO: rp.name_department,
      DATA: rp.date,
      DESCONTO_DOTZ: rp.discount_dotz,
      RECEITA_DOTZ: rp.income_dotz,
      EXTRATO: rp.total,
    }
  })
}

export const formatDataToCSVSynthetic = (data: ResponseReport[]) => {
  const values = calcValuesDotz(data);
  return values.map((rp, i) => {
    return {
      COD_COLIGADA: rp.cod_coligada,
      COLIGADA: rp.name_coligada,
      EMPREENDIMENTO: rp.enterprise,
      COD_DEPARTAMENTO: rp.cod_department,
      DEPARTAMENTO: rp.name_department,
      DESCONTO_DOTZ: rp.discount_dotz,
      RECEITA_DOTZ: rp.income_dotz,
      EXTRATO: rp.total,
    }
  })
}

export const formatDataToCSVReferFriend = (data: ResponseReferFriendsReport[]) => {
  return data.map((rp) => {
    return {
      NOME_INDICADO: rp.indication_name,
      CPF_INDICADO: rp.indication_document,
      EMAIL_INDICADO: rp.indication_email,
      NUMERO_INDICADO: rp.indication_phone_number,
      DATA_ASS_CONTRATO_INDICADO: rp.indication_contract_signing_date,
      DATA_VENDA_INDICADO: rp.indication_sale_date,
      BLOCO_EMPREENDIMENTO_INDICADO: rp.indication_enterprise_block,
      COD_EMPREENDIMENTO_INDICADO: rp.indication_enterprise_code,
      EMPREENDIMENTO_INDICADO: rp.indication_enterprise_name,
      UNIDADE_INDICADO: rp.indication_enterprise_unit,
      NUM_VENDA_INDICADO: rp.indication_sale_number,
      EMAIL_IMOBILIARIA_INDICADO: rp.indication_agency_email,
      IMOBILIARIA_INDICADO: rp.indication_agency_name,

      CPF_INDICADOR: rp.indicator_document,
      NOME_INDICADOR: rp.indicator_name,
      DATA_ASS_CONTRATO_INDICADOR: rp.indicator_contract_signing_date,
      DATA_VENDA_INDICADOR: rp.indicator_sale_date,
      COD_EMPREENDIMENTO_INDICADOR: rp.indicator_enterprise_code,
      EMPREENDIMENTO_INDICADOR: rp.indicator_enterprise_name,
      NUM_VENDA_INDICADOR: rp.indicator_sale_number,
      BLOCO_EMPREENDIMENTO_INDICADOR: rp.indicator_enterprise_block,
      UNIDADE_INDICADOR: rp.indicator_enterprise_unit,
      EMAIL_IMOBILIARIA_INDICADOR: rp.indicator_agency_email,
      IMOBILIARIA_INDICADOR: rp.indicator_agency_name,
      LINK_COMPARTILHADO: rp.shared_link,

      CONTA: rp.account_name,
      NUMERO_CONTA: rp.account_number,
      AGENCIA: rp.bank_branch,
      CODIGO_BANCO: rp.bank_code,
      NOME_BANCO: rp.bank_name,
      CHAVE_PIX: rp.pix_key,
      TIPO_CHAVE_PIX: rp.type_pix_key,
      
      STATUS: rp.status,
      PAGO: rp.has_paid ? 'Sim' : 'Não',
      DATA_INDICACAO: rp.indication_date,
     
    }
  })
}