import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Reports from '../pages/Reports';
import Login from '../pages/Login';
import ManageUser from '../pages/ManageUser';
import UserData from '../pages/UserData';
import ForgotPassword from '../pages/ForgotPassword';
import RedefinePassword from '../pages/RedefinePassword';
import Marketing from '../pages/Maketing';
import ListData from '../pages/Maketing/ListData';
import ManageContent from '../pages/Maketing/ManageContent';
import Article from '../pages/Article';
import Mission from '../pages/Maketing/ManageContent/Mission';
import Announcement from '../pages/Maketing/ManageContent/Announcement';
import Archive from '../pages/Maketing/ManageContent/Archive';
import ReferFriends from '../pages/ReferFriends';

const AppRoutes: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/redefine-password" element={<RedefinePassword />} />
      
      <Route path="/" element={<PrivateRoute path="/" component={Reports} />} />
      <Route path="/refer-friends" element={<PrivateRoute path="/refer-friends" component={ReferFriends} />} />
      <Route path="/user/update" element={<PrivateRoute path="/user/update" component={UserData} />} />
      <Route path="/user/manage" element={<PrivateRoute path="/user/manage" component={ManageUser} />} />

      <Route path="/marketing" element={<PrivateRoute path="/marketing" component={Marketing} />} />
      <Route path="/marketing/list-data" element={<PrivateRoute path="/marketing/list-data" component={ListData} />} />
      <Route path="/marketing/article" element={<PrivateRoute path="/marketing/article" component={Article} />} />
      <Route path="/marketing/manage-content/mission" element={<PrivateRoute path="/marketing/manage-content/mission" component={Mission} />} />
      <Route path="/marketing/manage-content/announcement" element={<PrivateRoute path="/marketing/manage-content/announcement" component={Announcement} />} />
      <Route path="/marketing/manage-content/archive" element={<PrivateRoute path="/marketing/manage-content/archive" component={Archive} />} />


      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);

export default AppRoutes;
